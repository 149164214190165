import { Controller } from "@hotwired/stimulus"
import { Editor } from "@tiptap/core"
import { extensions } from "./extensions"
import {
  updateDynamicFieldsPositions,
  updateSelectedDynamicField,
} from "./custom_extensions/dynamic_fields/utils"

export default class extends Controller {
  static targets = [
    "editor",
    "control",
    "selectedNodeAttributes",
    "attribute",
    "content",
  ]

  connect() {
    this.editor = new Editor({
      element: this.editorTarget,
      extensions: extensions,
      autofocus: true,
      content: this.contentTarget.value,
      editable: this.editorTarget.dataset.editable === "true",
      onCreate: () => this.updateButtonStates(),
      onSelectionUpdate: ({ editor }) => {
        this.updateButtonStates()
        updateSelectedDynamicField(
          editor,
          this.selectedNodeAttributesTarget,
          this.attributeTargets
        )
      },
    })

    this.editor.chain().focus().setFontSize("12pt").run()
  }

  updateButtonStates() {
    this.controlTargets.forEach((controlTarget) => {
      switch (controlTarget.dataset.extension) {
        case "bold":
        case "italic":
        case "underline":
        case "decimalList":
        case "lowerAlphaList":
        case "lowerRomanList":
        case "bulletList":
          this.updateActiveClass(controlTarget, [
            controlTarget.dataset.extension,
          ])
          break
        case "heading":
          this.updateActiveClass(controlTarget, [
            controlTarget.dataset.extension,
            { level: parseInt(controlTarget.dataset.level) },
          ])
          break
        case "textAlign":
          this.updateActiveClass(controlTarget, [
            { textAlign: controlTarget.dataset.alignment },
          ])
          break
        case "fontSize":
          this.updateFontSizeValue(controlTarget)
          break
      }
    })
  }

  updateFontSizeValue(element) {
    const fontSize = this.editor.getAttributes("textStyle").fontSize
    if (fontSize) {
      const size = parseInt(fontSize.replace("pt", ""))
      element.value = size
    } else {
      element.value = ""
    }
  }

  updateActiveClass(element, params) {
    const classList = element.classList

    this.editor.isActive(...params)
      ? classList.add("active")
      : classList.remove("active")
  }

  toggleBold() {
    this.editor.chain().focus().toggleBold().run()
    this.updateButtonStates()
  }

  toggleItalic() {
    this.editor.chain().focus().toggleItalic().run()
    this.updateButtonStates()
  }

  toggleUnderline() {
    this.editor.chain().focus().toggleUnderline().run()
    this.updateButtonStates()
  }

  toggleHeading(event) {
    const level = event.currentTarget.dataset.level

    this.editor
      .chain()
      .focus()
      .toggleHeading({ level: parseInt(level) })
      .run()
    this.updateButtonStates()
  }

  toggleBulletList() {
    this.editor.chain().focus().toggleBulletList().run()
    this.updateButtonStates()
  }

  toggleList(event) {
    const type = event.currentTarget.dataset.extension
    const commandName = `toggle${type.charAt(0).toUpperCase() + type.slice(1)}`

    this.editor.chain().focus()[commandName]().run()
    this.updateButtonStates()
  }

  setTextAlign(event) {
    const align = event.currentTarget.dataset.alignment

    this.editor.chain().focus().setTextAlign(align).run()
    this.updateButtonStates()
  }

  insertTable() {
    this.editor
      .chain()
      .focus()
      .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
      .run()
  }

  addColumnBefore() {
    this.editor.chain().focus().addColumnBefore().run()
  }

  addColumnAfter() {
    this.editor.chain().focus().addColumnAfter().run()
  }

  deleteColumn() {
    this.editor.chain().focus().deleteColumn().run()
  }

  addRowBefore() {
    this.editor.chain().focus().addRowBefore().run()
  }

  addRowAfter() {
    this.editor.chain().focus().addRowAfter().run()
  }

  deleteRow() {
    this.editor.chain().focus().deleteRow().run()
  }

  toggleHeaderRow() {
    this.editor.chain().focus().toggleHeaderRow().run()
  }

  setImage(event) {
    const url = event.currentTarget.dataset.src
    this.editor.chain().focus().setImage({ src: url }).run()
  }

  setFontSize(event) {
    let fontSize = parseInt(event.currentTarget.value)
    fontSize = Math.max(1, Math.min(fontSize, 100))
    this.editor.commands.setFontSize(`${fontSize}pt`)

    this.updateButtonStates()
  }

  preventFormSubmission(event) {
    if (event.key === "Enter") {
      event.preventDefault()
      this.editor.chain().focus().run()
    }
  }

  insertDynamicField(event) {
    this.editor.commands.setDynamicField(event.currentTarget.dataset.label)
  }

  prepareToSubmit() {
    updateDynamicFieldsPositions(this.editor)
    this.contentTarget.value = this.editor.getHTML()
  }
}
