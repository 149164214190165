import React from "react"
const Requests = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.666 7.333a1.992 1.992 0 0 0 1.994-2 1.997 1.997 0 1 0-3.993 0c0 1.107.893 2 2 2Zm-5.333 0a1.992 1.992 0 0 0 1.994-2 1.997 1.997 0 1 0-3.993 0c0 1.107.892 2 2 2Zm0 1.333C3.78 8.666.667 9.446.667 11v1.666H10V11c0-1.554-3.114-2.334-4.667-2.334Zm5.333 0a7.79 7.79 0 0 0-.646.034c.773.56 1.313 1.313 1.313 2.3v1.666h4V11c0-1.554-3.113-2.334-4.667-2.334Z" />
    </svg>
  )
}

export default Requests
