import React from "react"
const Director = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.667 6.287A2.336 2.336 0 0 1 8 3.953a2.336 2.336 0 0 1 2.333 2.334A2.336 2.336 0 0 1 8 8.62a2.336 2.336 0 0 1-2.333-2.333Zm1.333 0c0 .553.447 1 1 1 .553 0 1-.447 1-1 0-.554-.447-1-1-1-.553 0-1 .446-1 1Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.333 8A6.67 6.67 0 0 1 8 1.333 6.669 6.669 0 0 1 14.667 8 6.67 6.67 0 0 1 8 14.667 6.67 6.67 0 0 1 1.333 8ZM4.9 12.333c.873.627 1.94 1 3.1 1 1.16 0 2.227-.373 3.1-1-.873-.626-1.94-1-3.1-1-1.16 0-2.227.374-3.1 1ZM8 10c1.547 0 2.967.533 4.093 1.413A5.332 5.332 0 0 0 8 2.666a5.332 5.332 0 0 0-4.093 8.747A6.631 6.631 0 0 1 8 10Z"
      />
    </svg>
  )
}

export default Director
