import React from "react"

const Payees = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.333 2H2.667c-.734 0-1.334.6-1.334 1.333v9.334c0 .733.6 1.333 1.334 1.333h10.666c.734 0 1.334-.6 1.334-1.333V3.333c0-.733-.6-1.333-1.334-1.333ZM6 11.333H4a.669.669 0 0 1-.667-.666c0-.367.3-.667.667-.667h2c.367 0 .667.3.667.667 0 .366-.3.666-.667.666Zm0-2.666H4A.669.669 0 0 1 3.333 8c0-.367.3-.667.667-.667h2c.367 0 .667.3.667.667 0 .367-.3.667-.667.667ZM6 6H4a.669.669 0 0 1-.667-.667c0-.366.3-.666.667-.666h2c.367 0 .667.3.667.666C6.667 5.7 6.367 6 6 6Zm6.467 1.413-2.114 2.114a.672.672 0 0 1-.946 0l-.94-.947a.664.664 0 1 1 .94-.94l.473.473 1.647-1.646c.26-.26.68-.26.94 0l.006.006a.67.67 0 0 1-.006.94Z"
      />
    </svg>
  )
}

export default Payees
