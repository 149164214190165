import React from "react"

const Repayments = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.333 4v4.78c0 .354.14.694.394.947L4.94 12.94c.253.254.593.394.947.394h7.446c.734 0 1.334-.6 1.334-1.334V4c0-.733-.6-1.333-1.334-1.333H2.667c-.734 0-1.334.6-1.334 1.333ZM11.1 8.233l-1.86 1.86a.334.334 0 0 1-.573-.233V8.667H6.673a.667.667 0 0 1 0-1.333h1.994V6.14c0-.3.36-.446.566-.233l1.86 1.86c.134.127.134.34.007.466Z" />
    </svg>
  )
}

export default Repayments
