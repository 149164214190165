import React from "react"
const Requests = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
      <path d="M13.333 1.333H2.667c-.74 0-1.334.594-1.334 1.334V10c0 .74.594 1.333 1.334 1.333h2.666v3.334L8 13.333l2.667 1.334v-3.334h2.666c.74 0 1.334-.593 1.334-1.333V2.667c0-.74-.594-1.334-1.334-1.334Zm0 8.667H2.667V8.667h10.666V10Zm0-3.333H2.667V3.333c0-.366.3-.666.666-.666h9.334c.366 0 .666.3.666.666v3.334Z" />
    </svg>
  )
}

export default Requests
