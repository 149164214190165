import { Controller } from "stimulus"
import * as pbi from "powerbi-client"

export default class extends Controller {
  static targets = ["container"]
  static values = { workspaceId: String, reportId: String }

  connect() {
    powerbi.bootstrap(this.containerTarget, { type: "report" })

    fetch(this.embeddedConfigMicrosoftIntegrationWorkspaceReportPath())
      .then((response) => this.handleResponse(response))
      .then((embedConfig) => {
        this.embedReport(embedConfig)
      })
      .catch((error) => this.handleError(error))
  }

  embedReport(embedConfig) {
    const reportLoadConfig = {
      type: "report",
      tokenType: pbi.models.TokenType.Embed,
      accessToken: embedConfig.token,
      embedUrl: embedConfig.embed_url,
    }

    powerbi.embed(this.containerTarget, reportLoadConfig)
  }

  embeddedConfigMicrosoftIntegrationWorkspaceReportPath() {
    return [
      "/microsoft_integration",
      "/workspaces",
      `/${this.workspaceIdValue}`,
      "/reports",
      `/${this.reportIdValue}`,
      "/embedded_config",
    ].join("")
  }

  handleResponse(response) {
    if (!response.ok) {
      throw new Error("Failed to fetch embedded config")
    }

    return response.json()
  }

  handleError(error) {
    console.error(error)
    this.containerTarget.innerHTML = this.displayErrorMessage()
  }

  displayErrorMessage() {
    return `
    <div class="bg-danger-subtle p-3 rounded border-danger border">
      An error occurred while fetching the report. Our engineering team has been notified. Please try again later.
    </div>
  `
  }
}
