import React from "react"
import PropTypes from "prop-types"

export default function CountrySelect({ countrySelectChange, countryCode }) {
  return (
    <div>
      <div className="card">
        <div className="card-header">Destination country</div>
        <div className="card-body">
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Country</label>

              <select
                id="beneficiary_country_code"
                className="form-control"
                name="client_beneficiary[country_code]"
                value={countryCode}
                onChange={countrySelectChange}
              >
                <option value="AUS">AUS</option>
                <option value="USA">USA</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
