import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["selectSwitch", "noteInput"]
  static otherOption = "Other (Please specify)"
  static hiddenClass = "d-none"

  connect() {
    this.initSelectSwitch()
    this.updateNoteVisibility()
  }

  updateNoteValue() {
    if (this.selectSwitchTarget.value === this.constructor.otherOption) {
      if (this.indexOfSelectSwitchValue(this.noteInputTarget.value) !== null) {
        this.noteInputTarget.value = ""
      }
    } else {
      this.noteInputTarget.value = this.selectSwitchTarget.value
    }
    this.updateNoteVisibility()
  }

  updateNoteVisibility() {
    if (this.selectSwitchTarget.value === this.constructor.otherOption) {
      this.noteInputTarget.classList.remove(this.constructor.hiddenClass)
      this.noteInputTarget.required = true
    } else {
      this.noteInputTarget.classList.add(this.constructor.hiddenClass)
      this.noteInputTarget.required = false
    }
  }

  indexOfSelectSwitchValue(value) {
    const options = this.selectSwitchTarget.options
    for (let i = 0; i < options.length; i++) {
      if (options[i].text === value) {
        return i
      }
    }
    return null
  }

  initSelectSwitch() {
    if (this.noteInputTarget.value.length === 0) {
      this.noteInputTarget.value = this.selectSwitchTarget.options[0].text
    }
    let index = this.indexOfSelectSwitchValue(this.noteInputTarget.value)
    if (index === null) {
      index = this.selectSwitchTarget.options.length - 1
    }
    this.selectSwitchTarget.options[index].selected = true
  }
}
