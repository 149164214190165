import { Controller } from "stimulus"
import flatpickr from "flatpickr"
import $ from "jquery"

import Select2 from "select2"

export default class extends Controller {
  connect() {
    flatpickr(".flatpickr-datetime", {
      enableTime: true,
      altFormat: "Y-m-d H:i",
      dateFormat: "Z",
      altInput: true,
      minDate: "today",
      minuteIncrement: 0,
      defaultMinute: 0,
      time_24hr: true,
    })

    $(".select2-multiple").select2()
  }
}
