import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "form"]

  connect() {
    this.updateFormAction()
  }

  updateFormAction() {
    const selectedCompanyId = this.selectTarget.value
    if (selectedCompanyId) {
      this.formTarget.action = `/brokers/companies/${selectedCompanyId}/applications`
    }
  }

  change(event) {
    this.updateFormAction()
  }
}
