import React from "react"
const Cards = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.333 2.667H2.667c-.74 0-1.327.593-1.327 1.333l-.007 8c0 .74.594 1.333 1.334 1.333h10.666c.74 0 1.334-.593 1.334-1.333V4c0-.74-.594-1.333-1.334-1.333ZM12.667 12H3.333a.669.669 0 0 1-.666-.667V8h10.666v3.333c0 .367-.3.667-.666.667Zm.666-6.667H2.667v-.666c0-.367.3-.667.666-.667h9.334c.366 0 .666.3.666.667v.666Z" />
    </svg>
  )
}

export default Cards
