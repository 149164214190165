import { Controller } from "stimulus"
import "ninja-keys"

export default class extends Controller {
  connect() {
    this.element.data = [
      {
        id: "Applications",
        title: "Open Applications Pipeline",
        hotkey: "ctrl+A+P",
        mdIcon: "view_column",
        section: "Pipelines",
        handler: () => {
          window.location.href = "/admin/pipeline"
        },
      },
      {
        id: "Applications Grid",
        title: "Open Applications Grid",
        hotkey: "ctrl+A+G",
        mdIcon: "view_module",
        section: "Pipelines",
        handler: () => {
          window.location.href = "/admin/applications"
        },
      },
      {
        id: "Clients",
        title: "Open Clients Pipeline",
        hotkey: "ctrl+L+P",
        mdIcon: "view_column",
        section: "Pipelines",
        handler: () => {
          window.location.href = "/admin/applications/live_pipeline"
        },
      },
      {
        id: "Notes",
        title: "Open Notes & Mentions",
        hotkey: "ctrl+N+M",
        mdIcon: "comment",
        section: "Notes",
        handler: () => {
          window.location.href = "/admin/mentions"
        },
      },
      {
        id: "Beneficiaries",
        title: "Open Beneficiaries",
        hotkey: "ctrl+B",
        mdIcon: "money",
        section: "Beneficiaries",
        handler: () => {
          window.location.href = "/admin/client/beneficiaries"
        },
      },
      {
        id: "All Invoices",
        title: "Open All Invoices",
        hotkey: "ctrl+I+A",
        mdIcon: "receipt_long",
        section: "Invoices",
        handler: () => {
          window.location.href =
            "/admin/client/wallet/invoices?decision_type=all"
        },
      },
      {
        id: "Outstanding Invoices",
        title: "Open Outstanding Invoices",
        hotkey: "ctrl+I+O",
        mdIcon: "receipt_long",
        section: "Invoices",
        handler: () => {
          window.location.href =
            "/admin/client/wallet/invoices?decision_type=outstanding"
        },
      },
      {
        id: "Failed Invoices",
        title: "Open Failed Invoices",
        hotkey: "ctrl+I+F",
        mdIcon: "receipt_long",
        section: "Invoices",
        handler: () => {
          window.location.href =
            "/admin/client/wallet/invoices?decision_type=failed"
        },
      },
      {
        id: "Providers",
        title: "Open Providers",
        hotkey: "ctrl+P+R",
        mdIcon: "query_stats",
        section: "Providers",
        handler: () => {
          window.location.href = "/admin/providers"
        },
      },
      {
        id: "All Info Requests",
        title: "Open All Info Requests",
        hotkey: "ctrl+I+R",
        mdIcon: "list",
        section: "Info Requests",
        handler: () => {
          window.location.href = "/admin/info_requests?status=all"
        },
      },
      {
        id: "Sent Info Requests",
        title: "Open Sent Info Requests",
        hotkey: "ctrl+I+S",
        mdIcon: "list",
        section: "Info Requests",
        handler: () => {
          window.location.href = "/admin/info_requests?status=sent"
        },
      },
      {
        id: "Submitted Info Requests",
        title: "Open Submitted Info Requests",
        hotkey: "ctrl+I+U",
        mdIcon: "list",
        section: "Info Requests",
        handler: () => {
          window.location.href = "/admin/info_requests?status=submitted"
        },
      },
      {
        id: "Closed Info Requests",
        title: "Open Closed Info Requests",
        hotkey: "ctrl+I+C",
        mdIcon: "list",
        section: "Info Requests",
        handler: () => {
          window.location.href = "/admin/info_requests?status=closed"
        },
      },
      {
        id: "All Credit Reviews",
        title: "Open All Credit Reviews",
        hotkey: "ctrl+C+R",
        mdIcon: "fact_check",
        section: "Credit Reviews",
        handler: () => {
          window.location.href = "/admin/credit_reviews.all"
        },
      },
      {
        id: "Users",
        title: "Open Users",
        hotkey: "ctrl+U",
        mdIcon: "group",
        section: "Users",
        handler: () => {
          window.location.href = "/admin/users"
        },
      },
      {
        id: "Add User",
        title: "Add Users",
        hotkey: "ctrl+U+A",
        mdIcon: "person_add",
        section: "Users",
        handler: () => {
          window.location.href = "/admin/users/new"
        },
      },
      {
        id: "Sidekiq",
        title: "Open Sidekiq",
        hotkey: "ctrl+S+Q",
        mdIcon: "developer_board",
        section: "Developers",
        handler: () => {
          window.location.href = "/sidekiq"
        },
      },
      {
        id: "Impersonations",
        title: "Open Impersonation page",
        hotkey: "ctrl+I+N",
        mdIcon: "input",
        section: "Impersonation",
        handler: () => {
          window.location.href = "/admin/users/impersonators"
        },
      },
    ]
  }
}
