export class URL {
  constructor(url) {
    this.url = url
  }
  toString() {
    return this.url
  }
}

const coreapp = process.env.REACT_APP_CORE_APP_URL || "http://localhost:3000"
const website = process.env.REACT_APP_WEBSITE_URL || "http://localhost:3001"
const rootDomain = process.env.REACT_APP_DOMAIN || "http://localhost:3001"

export const APP = {
  root: new URL(`/`),
  domain: new URL(`${rootDomain}`),
  login: new URL(`/signin`),
  signin: new URL(`/signin`),
  signup: new URL(`/signup`),
  signout: new URL(`/signout`),
  dashboard: new URL(`/dashboard`),
  company: new URL(`/company`),
  director: new URL(`/director-details`),
  tailor: new URL("/tailor-your-experience"),
  connections: new URL("/connections"),
}

export const COREAPP = {
  root: new URL(`${coreapp}`),
  forgotPassword: new URL(`${coreapp}/users/password/new`),
  confirmationInstructions: new URL(`${coreapp}/users/confirmation/new`),
  unlockInstructions: new URL(`${coreapp}/users/unlock/new`),
  signOut: new URL(`${coreapp}/users/sign_out`),
}

export const CLIENT_APP = {
  home: new URL(`${website}/`),
  signOut: new URL(`${website}/signout`),
}
