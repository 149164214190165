import consumer from "./consumer"

consumer.subscriptions.create("MentionChannel", {
  connected() {
    this.perform("check")
  },

  received(data) {
    const elements = document.querySelectorAll(".mention-bell")

    elements.forEach((element) => {
      if (data["action"] == "create") {
        element.setAttribute("visibility", "")
      } else {
        element.setAttribute("visibility", "hidden")
      }
    })
  },
})
