import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["targetDropdown", "expectedValueDropdown"]
  static values = { targets: String }

  updateExpectedValues() {
    this.expectedValueDropdownTarget.innerHTML = ""

    JSON.parse(this.targetsValue)[this.targetDropdownTarget.value].forEach(
      (value) => {
        const option = document.createElement("option")
        option.value = value
        option.text = value
        this.expectedValueDropdownTarget.appendChild(option)
      }
    )
  }
}
