import styled from "styled-components"
import React from "react"

const LogoSidebar = () => {
  return (
    <LogoContainer>
      <svg fill="none" viewBox="0 0 127 24" width="95">
        <path
          shapeRendering="geometricPrecision"
          d="M12.129.643C5.179.643.257 5.598.257 12.322S5.18 24 12.13 24 24 19.046 24 12.322C24 5.598 19.078.643 12.129.643Zm0 5.148c3.667 0 6.273 2.67 6.273 6.53 0 3.861-2.606 6.531-6.273 6.531-3.668 0-6.274-2.67-6.274-6.53 0-3.86 2.606-6.531 6.274-6.531Zm28.798 2.211a.2.2 0 0 0-.2-.2h-4.619a.2.2 0 0 0-.2.2v8.373c0 2.011-1.094 2.928-2.59 2.928-1.512 0-2.59-.917-2.59-2.928V8.002a.2.2 0 0 0-.2-.2h-4.634a.2.2 0 0 0-.2.2v8.808c0 4.954 3.41 7.19 7.624 7.19 4.199 0 7.609-2.236 7.609-7.174V8.002Zm16.735 0a.2.2 0 0 0-.2-.2h-6.984a.2.2 0 0 1-.2-.2v-4.2a.2.2 0 0 0-.2-.2h-4.635a.2.2 0 0 0-.2.2v4.2a.2.2 0 0 1-.2.2H42.5a.2.2 0 0 0-.2.2v4.055c0 .11.09.2.2.2h2.543c.11 0 .2.09.2.2v5.06c0 3.957 1.914 6.483 6.145 6.483 1.362 0 3.212-.548 3.724-.709a.195.195 0 0 0 .137-.188v-3.729c0-.465-.507-.752-.927-.555-.582.274-1.212.484-1.808.484-1.27 0-2.236-.611-2.236-2.429v-4.417c0-.11.09-.2.2-.2h6.984a.2.2 0 0 0 .2-.2V8.002Z"
          fill="#fff"
        ></path>
        <path
          shapeRendering="geometricPrecision"
          d="M66.805 8.002a.2.2 0 0 0-.2-.2H62.58a.2.2 0 0 1-.2-.2v-.91c0-1.368.547-1.995 1.657-1.995.894 0 1.594.262 2.293.607.095.047.21.014.264-.078l2.001-3.4a.197.197 0 0 0-.053-.259C67.323.685 65.73 0 63.363 0c-3.684 0-6.017 2.332-6.017 6.58v16.802c0 .11.09.2.2.2h4.635a.2.2 0 0 0 .2-.2V12.457c0-.11.09-.2.2-.2h4.024a.2.2 0 0 0 .2-.2V8.002Zm16.674 0a.2.2 0 0 0-.2-.2H78.66a.2.2 0 0 0-.2.2v8.373c0 2.011-1.094 2.928-2.59 2.928-1.512 0-2.59-.917-2.59-2.928V8.002a.2.2 0 0 0-.2-.2h-4.635a.2.2 0 0 0-.2.2v8.808c0 4.954 3.41 7.19 7.625 7.19 4.199 0 7.609-2.236 7.609-7.174V8.002Zm11.631-.635c-1.838 0-3.284.576-4.286 1.822-.127.158-.41.074-.41-.129V8.002a.2.2 0 0 0-.2-.2h-4.427a.2.2 0 0 0-.2.2v15.38c0 .11.09.2.2.2h4.635a.2.2 0 0 0 .2-.2v-8.294c0-2.042 1.159-2.991 2.558-2.991 1.56 0 2.493 1.045 2.493 2.911v8.374c0 .11.09.2.2.2h4.635a.2.2 0 0 0 .2-.2v-10.16c0-3.651-2.445-5.855-5.598-5.855Zm14.512 0c-4.231 0-7.351 3.668-7.351 8.3 0 4.665 3.12 8.333 7.335 8.333 1.87 0 3.212-.676 4.154-1.674.131-.14.382-.05.382.14v.916c0 .11.09.2.2.2h4.458a.2.2 0 0 0 .2-.2V.618a.2.2 0 0 0-.2-.2h-4.619a.2.2 0 0 0-.2.2V8.76c0 .187-.24.277-.374.146-.931-.921-2.223-1.539-3.985-1.539Zm1.19 4.697c1.979 0 3.378 1.528 3.378 3.62 0 2.09-1.399 3.619-3.378 3.619-2.01 0-3.362-1.56-3.362-3.62 0-2.058 1.352-3.619 3.362-3.619Z"
          fill="#fff"
        ></path>
        <rect x="121" y="18" width="6" height="6" rx="3" fill="#00A3D7"></rect>
      </svg>
    </LogoContainer>
  )
}

export default LogoSidebar

const LogoContainer = styled.div`
  margin: 20px 12px 20px;
`
