import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["amount"]

  increment() {
    this.updateValue(this.stepValue())
  }

  decrement() {
    this.updateValue(-this.stepValue())
  }

  stepValue() {
    return parseInt(this.element.dataset.step, 10)
  }

  updateValue(change) {
    const currentValue = parseInt(this.amountTarget.value, 10)
    const minValue = parseInt(this.amountTarget.min, 10)
    const maxValue = parseInt(this.amountTarget.max, 10)

    const newValue = currentValue + change

    if (newValue >= minValue && newValue <= maxValue) {
      this.amountTarget.value = newValue
    }
  }
}
