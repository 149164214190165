import React from "react"
const Invoices = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 2H3.333c-.733 0-1.326.6-1.326 1.333L2 12.667C2 13.4 2.593 14 3.327 14h9.34C13.4 14 14 13.4 14 12.667V6l-4-4ZM3.333 12.667V3.333h6v3.334h3.334v6H3.333ZM6 5.333C6 5.7 5.7 6 5.333 6a.669.669 0 0 1-.666-.667c0-.366.3-.666.666-.666.367 0 .667.3.667.666ZM6 8c0 .367-.3.667-.667.667A.669.669 0 0 1 4.667 8c0-.367.3-.667.666-.667.367 0 .667.3.667.667Zm0 2.667c0 .366-.3.666-.667.666a.669.669 0 0 1-.666-.666c0-.367.3-.667.666-.667.367 0 .667.3.667.667Z" />
    </svg>
  )
}

export default Invoices
