import React, { useEffect, useState } from "react"
import CountrySelect from "./CountrySelect"

const BankDetails = (props) => {
  const [beneficiaryBankAccountCurrency, setBeneficiaryBankAccountCurrency] =
    useState("")
  const [bankName, setBankName] = useState("")
  const [bankAccountName, setBankAccountName] = useState("")
  const [bankAccountNumber, setBankAccountNumber] = useState("")
  const [bankCode, setBankCode] = useState("")
  const [bankAccountNumberLabel, setBankAccountNumberLabel] = useState("")
  const [bankCodeLabel, setBankCodeLabel] = useState("")

  const bankCodeLabels = {
    USA: "ACH Routing number",
    AUS: "BSB",
    ESP: "Swift code",
    GBR: "Sort code",
  }

  useEffect(() => {
    setBeneficiaryBankAccountCurrency(props.beneficiaryBankAccountCurrency)
    setBankName(props.bankName)
    setBankAccountName(props.bankAccountName)
    setBankAccountNumber(props.bankAccountNumber)
    setBankCode(props.bankCode)
    setBankAccountNumberLabel(
      props.countryCode == "USA" ||
        props.countryCode == "AUS" ||
        props.countryCode == "GBR"
        ? "Bank account number"
        : "IBAN"
    )
    setBankCodeLabel(bankCodeLabels[props.countryCode] || "BIC")
  }, [])

  return (
    <div>
      <div className="card">
        <div className="card-header">Bank info</div>
        <div className="card-body">
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Bank name</label>
              <input
                id="client_beneficiary_bank_name"
                type="text"
                name="client_beneficiary[bank_name]"
                className="form-control"
                placeholder="Bank name"
                defaultValue={bankName}
              />
            </div>
            <div className="form-group col-md-6">
              <label>Bank account name</label>
              <input
                id="client_beneficiary_bank_account_name"
                type="text"
                name="client_beneficiary[bank_account_name]"
                className="form-control"
                placeholder="Bank account name"
                defaultValue={bankAccountName}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6">
              <label>{bankAccountNumberLabel}</label>
              <input
                className="form-control maskable-input"
                type="text"
                name="client_beneficiary[bank_account_number]"
                id="client_beneficiary_bank_account_number"
                required
                autoComplete="off"
                placeholder=""
                defaultValue={bankAccountNumber}
              />
            </div>

            <div className="form-group col-md-6">
              <label>{bankCodeLabel}</label>
              <input
                className="form-control maskable-input"
                type="text"
                name="client_beneficiary[bank_code]"
                id="client_beneficiary_bank_code"
                required
                autoComplete="off"
                placeholder=""
                defaultValue={bankCode}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BankDetails
