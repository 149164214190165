import React from "react"
const Wallet = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 12v2H2V2h12v2H6.667v8H14Zm-6-1.333h6.667V5.333H8v5.334ZM10.667 9c-.554 0-1-.447-1-1 0-.553.446-1 1-1 .553 0 1 .447 1 1 0 .553-.447 1-1 1Z" />
    </svg>
  )
}

export default Wallet
