import React from "react"
const Logout = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.333 3.333h-4v9.334h4c.367 0 .667.3.667.666 0 .367-.3.667-.667.667h-4C2.6 14 2 13.4 2 12.667V3.333C2 2.6 2.6 2 3.333 2h4C7.7 2 8 2.3 8 2.667c0 .366-.3.666-.667.666Z" />
      <path d="m11.907 5.907 1.86 1.86a.328.328 0 0 1-.007.466l-1.86 1.86a.33.33 0 0 1-.567-.233V8.667H6.667A.669.669 0 0 1 6 8c0-.367.3-.667.667-.667h4.666V6.14c0-.3.36-.447.574-.233Z" />
    </svg>
  )
}

export default Logout
