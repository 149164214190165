import styled from "styled-components"

export const StyledSidebar = styled.aside`
  background-color: rgb(13, 44, 79);
  min-height: 100vh;
  height: 100%;
  margin-left: 0;
  grid-template-rows: 120px 1fr 72px;
  width: 225px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  gap: 16px;
  min-width: 185px;
  display: flex;
  flex-direction: column;
  color: white;
  transition: margin 0.25s ease-out;
`

export const SidebarHeader = styled.div`
  padding-top: 18px;
  padding-left: 16px;
  padding-right: 16px;
`

export const SidebarBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  padding-left: 16px;
  padding-right: 16px;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    padding: 2px;
    background-color: #1c3654;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #1c3654;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #e4f0fe;
  }
`

export const SidebarLinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1px;
`

export const SidebarLogo = styled.div`
  padding-left: 16px;
  padding-bottom: 0px;
  a {
    display: flex;
  }
`

export const SidebarLinkGroupTitle = styled.div`
  padding-left: 16px;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0.03em;
  color: #4b6686;
`

export const LogoutButton = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  svg {
    fill: #a5bdd3;
    transition: all 0.15s ease-in;
  }

  button {
    border: none;
    cursor: pointer;
    height: 40px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    gap: 8px;
    padding-left: 16px;
    border-radius: 20px;
    background-color: transparent;
    color: #e4f0fe;
    transition: all 0.15s ease-in;
    font-weight: 500;
    font-size: 14px;
    width: 100%;

    &:hover {
      background-color: #204a79;
      color: white;
      svg {
        fill: white;
      }
    }
  }
`

export const SidebarFooter = styled.div``

export const ErrorSidebar = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  padding: 0 15px;
  margin: 0 0 20px 0;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #4b6686;
  text-align: center;
`
