import { Extension } from "@tiptap/core"

export const FontSize = Extension.create({
  name: "fontSize",

  addOptions() {
    return {
      types: ["textStyle"],
    }
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: function (element) {
              return element.style.fontSize.replace(/['"]+/g, "")
            },
            renderHTML: function (attributes) {
              if (!attributes.fontSize) {
                return {}
              }
              return {
                style: `font-size: ${attributes.fontSize}`,
              }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setFontSize: function (fontSize) {
        return function ({ chain }) {
          return chain().setMark("textStyle", { fontSize: fontSize }).run()
        }
      },
    }
  },
})
