import React from "react"
import { CLIENT_APP, COREAPP } from "./Routes"

const LogoutContainer = ({ children }) => {
  const logoutApp = () => {
    fetch(COREAPP.signOut, { method: "DELETE" }).then((_) => {
      window.location.assign(CLIENT_APP.signOut)
    })
  }

  return (
    <>
      <button onClick={logoutApp}>{children}</button>
    </>
  )
}

export default LogoutContainer
