import { Controller } from "stimulus"
import $ from "jquery"
import "select2"

export default class extends Controller {
  connect() {
    this.initializeSelect2()
  }

  initializeSelect2() {
    const modal =
      $(this.element).closest(".modal").length > 0
        ? $(this.element).closest(".modal")
        : null
    const options = {
      placeholder: this.data.get("placeholder") || "Select an option",
      allowClear: this.data.has("allowClear"),
      dropdownParent: modal,
      theme: "bootstrap-5",
    }

    $(this.element).select2(options)
  }

  disconnect() {
    $(this.element).select2("destroy")
  }
}
