document.addEventListener("turbo:load", () => {
  var trigger = document.getElementById("menu-toggle")
  var wrapper = document.getElementById("wrapper")

  if (trigger) {
    trigger.onclick = function (e) {
      e.preventDefault()
      wrapper.classList.toggle("toggled")
    }
  }
})
