import React from "react"
const Dashboard = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 8.667h5.333V2H2v6.667Zm1.333-5.334H6v4H3.333v-4ZM8.667 14H14V7.333H8.667V14ZM10 8.667h2.667v4H10v-4ZM8.667 2v4H14V2H8.667Zm4 2.667H10V3.333h2.667v1.334ZM2 14h5.333v-4H2v4Zm1.333-2.667H6v1.334H3.333v-1.334Z" />
    </svg>
  )
}

export default Dashboard
