import React from "react"
const Documents = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.333 10.667h5.334V12H5.333v-1.333Zm0-2.667h5.334v1.333H5.333V8Zm4-6.667H4c-.733 0-1.333.6-1.333 1.334v10.666c0 .734.593 1.334 1.326 1.334H12c.733 0 1.333-.6 1.333-1.334v-8l-4-4Zm2.667 12H4V2.667h4.667V6H12v7.333Z" />
    </svg>
  )
}

export default Documents
