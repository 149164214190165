import { Controller } from "stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  static values = { mode: String }

  connect() {
    flatpickr(".flatpickr-date", {
      allowInput: true,
      mode: this.modeValue || "single",
    })

    flatpickr(".flatpickr-datetime", {
      allowInput: true,
      enableTime: true,
      dateFormat: "Y-m-d H:i",
    })
  }
}
