import React from "react"
const Company = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 4.667V2H1.333v12h13.334V4.667H8Zm-4 8H2.667v-1.334H4v1.334ZM4 10H2.667V8.667H4V10Zm0-2.667H2.667V6H4v1.333Zm0-2.666H2.667V3.333H4v1.334Zm2.667 8H5.333v-1.334h1.334v1.334Zm0-2.667H5.333V8.667h1.334V10Zm0-2.667H5.333V6h1.334v1.333Zm0-2.666H5.333V3.333h1.334v1.334Zm6.666 8H8v-1.334h1.333V10H8V8.667h1.333V7.333H8V6h5.333v6.667ZM12 7.333h-1.333v1.334H12V7.333ZM12 10h-1.333v1.333H12V10Z" />
    </svg>
  )
}

export default Company
