import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["state", "stateAbbreviation"]

  updateAbbreviation() {
    if (!this.stateTarget || !this.stateAbbreviationTarget) return

    this.stateAbbreviationTarget.value =
      this.stateTarget.selectedOptions[0].getAttribute(
        "data-state-abbreviation"
      )
  }
}
