import React from "react"
import PropTypes from "prop-types"

class NavbarUsername extends React.Component {
  render() {
    return (
      <React.Fragment>
        <span className="nav-link disabled">{this.props.username}</span>
      </React.Fragment>
    )
  }
}

NavbarUsername.propTypes = {
  username: PropTypes.string,
}
export default NavbarUsername
