import React from "react"

const Logo = ({ variant }) => {
  let fill = variant === "dark" ? "#F2F6FA" : "#F2F6FA"

  return (
    <svg width="88" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.595 15.62c2.619 0 4.824-1.929 4.824-4.843 0-2.915-2.206-4.825-4.825-4.825s-4.805 1.91-4.805 4.825c0 2.914 2.186 4.844 4.805 4.844Zm0-1.614c-1.674 0-3.072-1.32-3.072-3.23 0-1.89 1.398-3.21 3.072-3.21 1.693 0 3.11 1.32 3.11 3.21 0 1.91-1.417 3.23-3.11 3.23ZM39.825 11.25c-.02 1.988-1.556 2.756-2.836 2.756-1.28 0-2.205-.985-2.205-2.54V6.188h-1.713v5.317c-.02 2.58 1.398 4.116 3.702 4.116 1.024 0 2.5-.512 3.052-1.674v1.438h1.713V6.188h-1.713v5.061ZM48.676 15.385V13.77h-.532c-1.516 0-1.733-1.24-1.713-2.324v-3.78h1.95V6.187h-1.95V3.175l-1.713.532v2.481h-1.655v1.477h1.655v3.8c0 2.285.807 3.92 3.288 3.92h.67ZM55.838 2.801V1.186h-.985c-2.462 0-3.466 1.655-3.466 4.057v.945h-1.772v1.477h1.772v7.72H53.1v-7.72h2.147V6.188H53.1V5.007c-.02-1.201.414-2.206 2.048-2.206h.69ZM63.14 11.25c-.02 1.988-1.555 2.756-2.835 2.756s-2.206-.985-2.206-2.54V6.188h-1.713v5.317c-.02 2.58 1.398 4.116 3.702 4.116 1.024 0 2.501-.512 3.053-1.674v1.438h1.713V6.188H63.14v5.061ZM71.981 5.952c-1.004 0-2.5.512-3.052 1.634V6.188h-1.713v9.197h1.713v-4.982c0-1.99 1.536-2.757 2.816-2.757 1.28 0 2.225.984 2.225 2.52v5.219h1.713v-5.317c.02-2.58-1.417-4.116-3.702-4.116ZM85.501 7.626c-.61-1.083-1.91-1.674-3.288-1.674-2.52 0-4.588 1.91-4.588 4.825 0 2.894 2.087 4.844 4.608 4.844 1.319 0 2.658-.63 3.268-1.674v1.438h1.714V1.186H85.5v6.44Zm-3.13 6.38c-1.674 0-3.013-1.418-3.013-3.25 0-1.85 1.339-3.19 3.013-3.19 1.516 0 3.13 1.202 3.13 3.19 0 1.97-1.476 3.25-3.13 3.25Z"
        fill="#ffffff"
      />
      <path
        d="M10.77 9.846h3.076a2.154 2.154 0 1 0 0-4.308H10.77a2.154 2.154 0 0 0 0 4.308Z"
        fill="#AC6AFF"
      />
      <path
        d="M10.77 4.308h5.538a2.154 2.154 0 0 0 0-4.308h-5.539a2.154 2.154 0 0 0 0 4.308Z"
        fill="#0D51FF"
      />
      <circle cx="11.077" cy="13.539" r="2.462" fill="#FFBB13" />
      <circle cx="3.692" cy="12.308" fill="#11C6FF" r="3.692" />
    </svg>
  )
}

export default Logo
