import React from "react"
const Offers = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.333 3.333v-.666c0-.367-.3-.667-.666-.667h-1.334c-.366 0-.666.3-.666.667v.666H10V6c0 .367.3.667.667.667h.666v4.666c0 .734-.6 1.334-1.333 1.334s-1.333-.6-1.333-1.334V4.667a2.666 2.666 0 1 0-5.334 0v4.666h-.666C2.3 9.333 2 9.633 2 10v2.667h.667v.666c0 .367.3.667.666.667h1.334c.366 0 .666-.3.666-.667v-.666H6V10c0-.367-.3-.667-.667-.667h-.666V4.667c0-.734.6-1.334 1.333-1.334s1.333.6 1.333 1.334v6.666a2.666 2.666 0 1 0 5.334 0V6.667h.666c.367 0 .667-.3.667-.667V3.333h-.667Z" />
    </svg>
  )
}

export default Offers
