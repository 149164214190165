import React from "react"
import PropTypes from "prop-types"

export default function CurrencySelect({ currencySelectChange, currencyCode }) {
  return (
    <div>
      <div className="card">
        <div className="card-header">Destination currency</div>
        <div className="card-body">
          <div className="form-row">
            <div className="form-group col-md-6">
              <label>Currency</label>

              <select
                id="beneficiary_bank_account_currency"
                className="form-control"
                name="beneficiary[bank_account_currency]"
                value={currencyCode}
                onChange={currencySelectChange}
              >
                <option value="AUD">AUD</option>
                <option value="USD">USD</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
