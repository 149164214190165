export const updateDynamicFieldsPositions = ({ state, view }) => {
  state.doc.descendants((node, pos) => {
    if (node.type.name === "dynamicField") {
      const dom = view.nodeDOM(pos)

      const rect = dom.getBoundingClientRect()
      const newAttrs = {
        ...node.attrs,
        posX: rect.x.toString(),
        posY: rect.y.toString(),
      }

      view.dispatch(view.state.tr.setNodeMarkup(pos, undefined, newAttrs))
    }
  })
}

export const updateSelectedDynamicField = (
  editor,
  selectedTarget,
  attributeTargets
) => {
  const { state } = editor
  const { from } = state.selection
  const node = state.doc.nodeAt(from)

  if (node && node.type.name === "dynamicField") {
    attributeTargets.forEach((attributeTarget) => {
      const attr = attributeTarget.dataset.attribute
      attributeTarget.innerHTML = node.attrs[attr]
    })
  }
}
