import styled from "styled-components/macro"

export const ExternalSidebarLink = ({ icon, label, link, faIcon }) => {
  return (
    <Link href={link}>
      <SidebarLinkContainer>
        {icon ? (
          <img src={icon} alt="Sidebar Icon" />
        ) : (
          <i className={faIcon}></i>
        )}
        {label}
      </SidebarLinkContainer>
    </Link>
  )
}

export default ExternalSidebarLink

const SidebarLinkContainer = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  gap: 10px;
  font-size: 14px;
  border-radius: 20px;
  text-decoration: none;

  &:hover {
    color: #37dbff;
    cursor: pointer;
    transition: all 0.25s ease-out;

    img {
      filter: invert(48%) sepia(39%) saturate(3805%) hue-rotate(158deg)
        brightness(100%) contrast(105%);
    }
  }
`

const Link = styled.a`
  text-decoration: none;
  color: #e4f0fe;
  margin: 5px 0 0 0;

  &.active {
    background: #204a79;
    color: #37dbff;
    cursor: pointer;
    position: relative;
    border-radius: 20px;

    &:after {
      content: "●";
      color: #37dbff;
      position: absolute;
      transform: scale(0.5, 0.5);
      right: 16px;
      bottom: 8px;
    }

    img {
      filter: invert(48%) sepia(39%) saturate(3805%) hue-rotate(158deg)
        brightness(100%) contrast(105%);
    }
  }
`
