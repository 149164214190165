import React from "react"
const Mandates = () => {
  return (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <path d="M13.333 16H2.667c-.734 0-1.334-.6-1.334-1.334 0-.733.6-1.333 1.334-1.333h10.666c.734 0 1.334.6 1.334 1.333 0 .734-.6 1.334-1.334 1.334ZM8.707 3.46l2.5 2.5-5.847 5.846a.645.645 0 0 1-.467.194h-1.56a.669.669 0 0 1-.666-.667v-1.56c0-.18.073-.346.193-.473l5.847-5.84Zm3.213 1.787-2.5-2.5 1.22-1.22c.26-.26.68-.26.94 0l1.56 1.56c.26.26.26.68 0 .94l-1.22 1.22Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Mandates
