import styled from "styled-components"

export const StyledSidebarLink = styled.div`
  svg {
    fill: #a5bdd3;
    transition: all 0.15s ease-in;
  }

  a {
    height: 40px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    gap: 8px;
    padding-left: 16px;
    border-radius: 20px;
    background-color: transparent;
    color: #e4f0fe;
    transition: all 0.15s ease-in;
    font-weight: 500;
    font-size: 14px;
    position: relative;

    &:after {
      content: "";
      background-color: #37dbff;
      border-radius: 6px;
      position: absolute;
      display: block;
      width: 6px;
      height: 6px;
      right: 20px;
      bottom: 17px;
      opacity: 0;
      transition: all 0.15s ease-in;
    }

    &:hover {
      background-color: #204a79;
      color: white;
      svg {
        fill: white;
      }
    }

    &.active {
      background-color: #204a79;
      color: #37dbff;
      svg {
        fill: #37dbff;
      }
      &:after {
        opacity: 1;
      }
    }
  }
`

export const CoreAppLink = styled.a``
