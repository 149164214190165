import { Document } from "@tiptap/extension-document"
import { Text } from "@tiptap/extension-text"
import { Paragraph } from "@tiptap/extension-paragraph"
import { Heading } from "@tiptap/extension-heading"
import { Bold } from "@tiptap/extension-bold"
import { Italic } from "@tiptap/extension-italic"
import { Underline } from "@tiptap/extension-underline"
import { TextAlign } from "@tiptap/extension-text-align"
import { Placeholder } from "@tiptap/extension-placeholder"
import { TextStyle } from "@tiptap/extension-text-style"
import { FontFamily } from "@tiptap/extension-font-family"
import { ListItem } from "@tiptap/extension-list-item"
import { BulletList } from "@tiptap/extension-bullet-list"
import { DynamicField } from "./custom_extensions/dynamic_field"
import { LowerAlphaList } from "./custom_extensions/lists/lower_alpha_list"
import { LowerRomanList } from "./custom_extensions/lists/lower_roman_list"
import { DecimalList } from "./custom_extensions/lists/decimal_list"
import { FontSize } from "./custom_extensions/font_size"
import { DefaultHardBreak } from "./custom_extensions/default_hard_break"
import { Gapcursor } from "@tiptap/extension-gapcursor"
import { Table } from "@tiptap/extension-table"
import { TableCell } from "@tiptap/extension-table-cell"
import { TableHeader } from "@tiptap/extension-table-header"
import { TableRow } from "@tiptap/extension-table-row"
import { Image } from "@tiptap/extension-image"

export const extensions = [
  Document,
  Text,
  Paragraph,
  DefaultHardBreak,
  FontFamily,
  TextStyle,
  Heading,
  Bold,
  Italic,
  Underline,
  TextAlign.configure({ types: ["heading", "paragraph"] }),
  Placeholder.configure({ placeholder: "Start your awesome contract..." }),
  ListItem,
  LowerAlphaList,
  LowerRomanList,
  DecimalList,
  BulletList,
  DynamicField,
  FontSize,
  Gapcursor,
  Table.configure({ resizable: true }),
  TableCell,
  TableHeader,
  TableRow,
  Image.configure({ inline: true, HTMLAttributes: { class: "image" } }),
]
