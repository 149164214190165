import React from "react"
const Facilities = () => {
  return (
    <svg width="12" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 3.667V5h4V3.667H4ZM4 7V5.667h4V7H4ZM10 3.667H8.667V5H10V3.667ZM8.667 5.667H10V7H8.667V5.667Z"
        fill="#A5BDD3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m11 1.333-1-1-1 1-1-1-1 1-1-1-1 1-1-1-1 1-1-1v9.334H0v2c0 1.106.893 2 2 2h8c1.107 0 2-.894 2-2V.333l-1 1Zm-9 11a.669.669 0 0 1-.667-.666V11H8v1.333H2Zm8.667-.666c0 .366-.3.666-.667.666a.669.669 0 0 1-.667-.666v-2h-6V2.333h7.334v9.334Z"
      />
    </svg>
  )
}

export default Facilities
