document.addEventListener("turbo:load", () => {
  document
    .querySelectorAll(".sidebar-links-group .collapse")
    .forEach(function (collapse) {
      collapse.querySelectorAll(".list-group-item").forEach(function (item) {
        if (item.classList.contains("active")) {
          collapse.classList.add("show")
          return
        }
      })
    })
})
