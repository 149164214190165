import { Node, mergeAttributes } from "@tiptap/core"

export const BaseList = ({ name, className, tag = "ol" }) => {
  return Node.create({
    name,

    group: "block list",
    content: "listItem+",
    defining: true,

    parseHTML() {
      return [
        {
          tag,
          getAttrs: (node) => node.classList.contains(className) && null,
        },
      ]
    },

    renderHTML({ HTMLAttributes }) {
      return [tag, mergeAttributes(HTMLAttributes, { class: className }), 0]
    },

    addCommands() {
      return {
        [`toggle${name.charAt(0).toUpperCase() + name.slice(1)}`]:
          () =>
          ({ commands }) => {
            return commands.toggleList(name, "listItem")
          },
      }
    },
  })
}
