import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["page", "currentPage"]

  connect() {
    this.setCurrentPageInnerHTML(0)
    this.showModal()
  }

  nextPage(event) {
    this.setCurrentPageInnerHTML(this.currentIndex(event) + 1)
  }

  previousPage(event) {
    this.setCurrentPageInnerHTML(this.currentIndex(event) - 1)
  }

  setCurrentPageInnerHTML(at_index) {
    this.currentPageTarget.innerHTML = this.pageTargets[at_index].innerHTML
  }

  currentIndex(event) {
    return parseInt(event.target.dataset.pageIndex)
  }
  showModal() {
    $(document).ready(function () {
      $(".modal").modal("show")
    })
  }
}
