import { Controller } from "stimulus"
import tippy from "tippy.js"

export default class extends Controller {
  static targets = ["source"]

  connect() {
    this.tippyInstance = tippy(this.element, {
      content: "Click to copy",
      trigger: "mouseenter focus",
      hideOnClick: false,
    })
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.sourceTarget.textContent).then(() => {
      this.tippyInstance.setContent("Copied!")

      setTimeout(() => {
        this.tippyInstance.setContent("Click to copy")
      }, 2000)
    })
  }
}
