import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["conditionalSelect", "conditionalInput"]

  connect() {
    this.toggleRequired()
  }

  toggleRequired() {
    const selectedValue = this.conditionalSelectTarget.value
    const conditionalInput = this.conditionalInputTarget

    if (selectedValue === "other") {
      conditionalInput.required = true
    } else {
      conditionalInput.required = false
    }
  }
}
